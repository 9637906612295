@import "normalize";

:root {
  --text-color: #101010;
  //--primary: #ed1786;
  //--secondary: #f95d6b;
  --container-width: 80%;
  --container-max-width: 1350px;
  --primary: #1e87f0;
  --secondary: #0c45b5;
}

@keyframes move-bg {
  to {
    background-position: 400% 0
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  line-height: 1.5;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  background: radial-gradient(circle at 85% 33%, rgba(0, 255, 235, .085), hsla(0, 0%, 100%, 0) 25%), radial-gradient(circle at 12% 55%, rgba(108, 99, 255, .1), hsla(0, 0%, 100%, 0) 25%);
  color: var(--text-color);
}

a {
  color: var(--primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.container {
  width: var(--container-width);
  max-width: var(--container-max-width);
  margin: 80px auto 0;
  padding: 0 1rem;
  @media (min-width: 768px) {
    margin: 144px auto 0;
  }
}

.title {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
  background: linear-gradient(30deg, var(--primary), var(--secondary), var(--primary)) 0 0/400% 100%;
  color: transparent;
  -webkit-background-clip: text;
  animation: move-bg 15s linear infinite;
  @media (min-width: 768px) {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
}

.footer {
  margin-top: auto;
  padding: 24px;
  border-top: 1px solid #ddd;

  @media (min-width: 768px) {
    padding: 56px 0;
  }

  p {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }
}
